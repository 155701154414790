import { ProductInterface } from '../../../interfaces/Product.ts';
import { PRODUCT_TYPE_ECARD, PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE, PRODUCT_TYPE_PHYSICAL_CARD, PRODUCT_TYPE_PRODUCT } from '../../../Constant.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import styled from 'styled-components';

type ProductTypeProps = {
    product: ProductInterface;
    classNames?: string[];
    display?: boolean;
};

const Span = styled.span<{ classNames?: string }>`
    font-size: ${props => props.theme.globalSize.mobileSize5};
    font-weight: 500;
    display: flex;
    width: max-content;
    height: 38px;
    padding: 0px 16px;
    gap: 4px;
    border-radius: 6px 6px 6px 6px;
    background: ${props => props.theme.colors.whiteLighter};
    font-family: ${props => props.theme.fonts.familyPrimary};
    line-height: 19.6px;
    letter-spacing: 0.005em;
    text-align: left;
    align-items: center;
    justify-content: center;

    &.cart {
        height: 26px;
        padding: 0px 8px 0px 8px;
        opacity: unset;
        font-size: ${props => props.theme.globalSize.mobileSize6};
        font-weight: 400;
        line-height: 14.06px;
        text-align: left;
        color: ${props => props.theme.colors.greyDark};
    }
`;

export default function ProductType({ product, classNames = [], display }: ProductTypeProps) {
    const { t } = useSharedTranslation();
    const isVirtual = product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE;
    const isPhysical = !isVirtual && (product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD || product.productType?.name === PRODUCT_TYPE_PRODUCT);
    const isDivisible = product?.productDivisibleChoice?.isDivisible;
    const isCumulative = product?.cumulative;

    const classes = classNames.join(' ');

    return (
        <>
            {isVirtual && <Span className={classes}>Virtuel</Span>}
            {isPhysical && <Span className={classes}>Physique</Span>}
            {!display && classes != 'cart' && (
                <>
                    <Span>{isCumulative ? t('product.products.cumulative') : t('product.products.notcumulative')}</Span>
                    <Span>{isDivisible ? t('product.products.divisible') : t('product.products.notdivisible')}</Span>
                </>
            )}
        </>
    );
}
